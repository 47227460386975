<template>
  <v-dialog
    v-model="isOpened"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-container>
        <v-form @submit.prevent="onSubmit" ref="form">
          <v-card-text>
            <v-select
              :items="regions.map(item => ({ value: item.id, text: item.name }))"
              label="Регион"
              v-model="modalUser.region_id"
            />

            <v-text-field
              label="ФИО"
              required
              v-model="modalUser.name"
            />

            <v-text-field
              label="Email"
              required
              v-model="modalUser.email"
            />

            <v-text-field
              label="Телефон"
              required
              v-model="modalUser.phone"
            />

            <v-select
              :items="types.map(item => ({ value: item.id, text: item.name }))"
              label="Тип"
              v-model="modalUser.type_id"
            />
            /
            <v-select
              :items="worker_types.map(item => ({ value: item.id, text: item.name }))"
              label="Роль"
              v-model="modalUser.worker_type_id"
            />

            <v-text-field
              label="Пароль"
              v-model="modalUser.password"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="onCancelClick"
            >
              Отмена
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              type="submit"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { ListsService, UsersService } from '@/common/api.service'

export default {
  props: {
    user: {
      default: null
    }
  },

  data() {
    return {
      regions: [],
      types: [],
      worker_types: [],
      modalUser: {
        region_id: null,
        name: null,
        email: null,
        phone: null,
        worker_type_id: null,
        type_id: null,
        password: null
      },
      isOpened: false,
    }
  },

  methods: {
    onSubmit() {
      if( this.user.id ) {
        UsersService.update(this.user.id, this.modalUser).then(() => this.$emit('close', true))
      } else {
        UsersService.add(this.modalUser, 'worker').then(() => this.$emit('close', true))
      }
    },

    onCancelClick() {
      this.$emit('close', false)
    }
  },

  watch: {
    user(value) {
      this.modalUser.password = null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }

      if (value) {
        this.isOpened = true

        for (const [key] of Object.entries(this.modalUser)) {
          this.modalUser[key] = this.user[key]
        }
      } else {
        this.isOpened = false
      }
    }
  },

  beforeMount() {
    ListsService.get(['regions', 'types', 'worker_types'])
      .then(({data}) => {
        this.regions = data.regions
        this.types = data.types
        this.worker_types = data.worker_types
      })
  }
}
</script>
