<template>
  <div>
    <v-row>
      <v-col>
        <Heading title="Поверители и Слесари"/>
      </v-col>
      <v-col align="right" align-self="center">
        <v-btn color="blue" outlined @click="onAddClick">Добавить</v-btn>
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="!isLoaded" :loading="!isLoaded" type="table-tbody"></v-skeleton-loader>

    <v-card v-for="user in users" :key="user.id" tile>
      <v-card-text class="d-flex">
        <v-row>
          <v-col sm="2">
            {{ user.worker_type ? user.worker_type.name : undefined }}
          </v-col>

          <v-col sm="1">
            {{ user.type ? user.type.name : undefined }}
          </v-col>

          <v-col sm="3">
            {{ user.name }}
          </v-col>

          <v-col sm="3">
            {{ user.phone }}
          </v-col>

          <v-col sm="3">
            {{ user.email }}
          </v-col>
        </v-row>

        <div class="buttons">
          <v-btn text small @click="onEditClick(user.id)">
            Редактировать
          </v-btn>

          <v-btn text small @click="onDeleteClick(user.id)">
            Удалить
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <DeleteConfirmation
      :visible="deleteConfirmationVisible"
      @confirm="deleteConfirm"
      @decline="deleteDecline"
    />

    <VerifierModal
      :user="modalItem"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import { UsersService } from '@/common/api.service'
import DeleteConfirmation from '@/components/Modals/DeleteConfirmation'
import VerifierModal from '@/views/FilialManager/Users/VerifierModal'

export default {
  components: {
    DeleteConfirmation,
    VerifierModal
  },

  data() {
    return {
      regions: [],
      users: [],
      isLoaded: false,
      toDeleteId: null,
      toEditId: null,
      deleteConfirmationVisible: false
    }
  },

  methods: {
    onAddClick() {
      this.toEditId = 0
    },

    onEditClick(id) {
      this.toEditId = id
    },

    onDeleteClick(id) {
      this.toDeleteId = id
      this.deleteConfirmationVisible = true
    },

    deleteConfirm() {
      this.deleteConfirmationVisible = false
      UsersService.delete(this.toDeleteId).then(() => this.fetchUsers())
    },

    deleteDecline() {
      this.deleteConfirmationVisible = false
    },

    onModalClose(needRefresh) {
      this.toEditId = null

      if (needRefresh) {
        this.users = []
        this.isLoaded = false
        this.fetchUsers()
      }
    },

    fetchUsers() {
      UsersService
        .workers(false, this.$store.state.auth.user.region_id)
        .then(({ data }) => {
          this.users = data.users
          this.isLoaded = true
        })
    }
  },

  computed: {
    modalItem() {
      return this.toEditId !== 0 ? this.users.find(user => user.id === this.toEditId) : {}
    }
  },

  beforeMount() {
    this.fetchUsers()
  }
}
</script>

<style scoped>
.buttons {
  display: flex;
  align-items: center;
}
</style>
