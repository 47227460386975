<template>
  <div>
    <v-row>
      <v-col>
        <Heading title="Поверители и Слесари" />
      </v-col>

      <v-col
        align="right"
        align-self="center"
      >
        <v-btn
          color="blue"
          outlined
          @click="onAddClick"
        >
          Добавить
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-1"
      :footer-props="{ itemsPerPageOptions: [5, 10, 20, 50] }"
      :items-per-page="20"
      :loading="!isLoaded"
    >
      <template #[`item.worker_type`]="{ item }">
        {{ item.worker_type ? item.worker_type.name : '-' }}
      </template>

      <template #[`item.type`]="{ item }">
        {{ item.type ? item.type.name : '-' }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn text small @click="onEditClick(item.id)">
          Редактировать
        </v-btn>

        <v-btn text small @click="onDeleteClick(item.id)">
          Удалить
        </v-btn>
      </template>
    </v-data-table>

    <DeleteConfirmation
      :visible="deleteConfirmationVisible"
      @confirm="deleteConfirm"
      @decline="deleteDecline"
    />

    <VerifierModal
      :user="modalItem"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import { UsersService } from '@/common/api.service'
import DeleteConfirmation from '@/components/Modals/DeleteConfirmation'
import VerifierModal from '@/views/Manager/Users/VerifierModal'

export default {
  components: {
    DeleteConfirmation,
    VerifierModal
  },

  data() {
    return {
      regions: [],
      users: [],
      isLoaded: false,
      toDeleteId: null,
      toEditId: null,
      deleteConfirmationVisible: false,

      headers: [
        {
          text: 'Должность',
          value: 'worker_type',
          align: 'left',
          sortable: false
        },
        {
          text: 'Тип',
          value: 'type',
          sortable: false
        },
        {
          text: 'Имя',
          value: 'name',
          sortable: false
        },
        {
          text: 'Телефон',
          value: 'phone',
          sortable: false
        },
        {
          text: 'E-mail',
          value: 'email',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false
        }
      ]
    }
  },

  methods: {
    onAddClick () {
      this.toEditId = 0
    },

    onEditClick (id) {
      this.toEditId = id
    },

    onDeleteClick (id) {
      this.toDeleteId = id
      this.deleteConfirmationVisible = true
    },

    deleteConfirm () {
      this.deleteConfirmationVisible = false
      UsersService.delete(this.toDeleteId).then(() => this.fetchUsers())
    },

    deleteDecline () {
      this.deleteConfirmationVisible = false
    },

    onModalClose (needRefresh) {
      this.toEditId = null

      if (needRefresh) {
        this.users = []
        this.isLoaded = false
        this.fetchUsers()
      }
    },

    fetchUsers () {
      UsersService
        .workers()
        .then(({ data }) => {
          this.users = data.users
          this.isLoaded = true
        })
    }
  },

  computed: {
    modalItem () {
      return this.toEditId !== 0 ? this.users.find(user => user.id === this.toEditId) : {}
    }
  },

  beforeMount () {
    this.fetchUsers()
  }
}
</script>
